import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Accessibility Feedback Process Description Template – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Accessibility Feedback Process Description Template</h1>
        <p className="font-bold">
        Purple Cow Internet is committed to ensuring our services are accessible for everyone. We welcome questions or feedback about any issues involving accessibility, including how we can remove any barriers to the accessibility of our products, services, website and points of sale. Please share your questions, comments and feedback using any of the methods below:
        </p>
        <p className="font-bold">
            Phone or SMS: 902 800 2660 <br></br>
            Email:  Team@purplecowinternet.com <br></br>
            Mail: 61 Raddall Ave Unit P Dartmouth NS B3B 1T2 <br></br>
        </p>
        <p className="font-bold">
             Your feedback will be received, reviewed, and addressed by Manager for Accessibility or Designate  and incorporated into our accessibility plan, which is currently in development, in accordance with the Accessible Canada Act.        
        </p>
        <p className="font-bold">
             Feedback can be submitted anonymously, however if you would like us to follow up with you, please provide your contact information, which will be kept confidential, and treated in accordance with our privacy policy.
        </p>
        <p className="font-bold">
            If you require a description of our accessibility feedback process in another format, please let us know. We will provide you with a print, large print, or adaptive electronic format version within 20 days, and a braille or audio version within 45 days of your request.
        </p>
      </section>
    </LayoutNew>
  )
}
